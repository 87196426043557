export interface TileConfiguration {
  id: string;
  elementName: string;
  hidden?: boolean;
}

export const defaultTileConfigurations: TileConfiguration[] = [
  {
    id: 'jh-inventory-stats',
    elementName: 'jh-inventory-stats',
    hidden: false,
  },
  {
    id: 'jh-access-management-tile',
    elementName: 'jh-access-management-tile',
    hidden: false,
  },
  {
    id: 'jh-detected-shocks-tile',
    elementName: 'jh-detected-shocks-tile',
    hidden: false,
  },
  {
    id: 'jh-preopcheck-tile',
    elementName: 'jh-preopcheck-tile',
    hidden: false,
  },
  {
    id: 'limits-lobby-tile',
    elementName: 'limits-lobby-tile',
    hidden: false,
  },
  {
    id: 'cost-split-tile',
    elementName: 'cost-split-tile',
    hidden: false,
  },
  {
    id: 'operating-hours-overview',
    elementName: 'operating-hours-overview',
    hidden: false,
  },
  {
    id: 'critical-equipment',
    elementName: 'critical-equipment',
    hidden: false,
  },
  {
    id: 'jh-dsm-maintenance-tile',
    elementName: 'jh-dsm-maintenance-tile',
    hidden: false,
  },
  {
    id: 'jh-dsm-service-reports-tile',
    elementName: 'jh-dsm-service-reports-tile',
    hidden: false,
  },
  {
    id: 'jh-dsm-battery-monitoring-tile',
    elementName: 'jh-dsm-battery-monitoring-tile',
    hidden: false,
  },
];
